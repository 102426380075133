import React, { useEffect, useState } from "react";
import Image from 'next/image';
import Snowfall from 'react-snowfall';

import classNames from "classnames";
import PropTypes from "prop-types";

// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";

// core components
import styles from "/styles/jss/bbhost/components/parallaxStyle.js";

import { getBGPath } from "configs/index";
import { isMobile } from 'react-device-detect';

const datasComemorativas = {
  datas: [
    {
      name: "hallowen",
      initDate: '10-28',
      finishDate: '10-31'
    },
    {
      name: "pre_natal",
      initDate: '11-20',
      finishDate: '12-09',
      effect: (<Snowfall style={{ zIndex: "10000", position: "fixed" }} speed={[0.5, 3]} snowflakeCount="75" />)
    },
    {
      name: "natal",
      initDate: '12-10',
      finishDate: '12-31',
      effect: (<Snowfall style={{ zIndex: "10000", position: "fixed" }} speed={[0.5, 3]} snowflakeCount="75" />)
    },
    {
      name: "ano_novo",
      initDate: '01-01',
      finishDate: '01-05',
    },
  ],
  isDataComemorativa: function () {
    const date = new Date();
    let isDataComemorativa = false;
    this.getDatasComemorativas().forEach((value) => {
      if(date.getTime() >= value.initDate && date.getTime() <= value.finishDate)
      {
        isDataComemorativa = true;
        return;
      }
    });
    return isDataComemorativa;
  },
  getDatasComemorativas: function () {
    const year = new Date().getFullYear();        
    return this.datas.map((value) => {
      return {
        initDate: (new Date(`${year}-${value.initDate} 00:00:00 -3`).getTime()),
        finishDate: (new Date( `${year}-${value.finishDate} 23:59:59 -3`).getTime()),
        name: value.name,
        effect: (value.effect !== undefined) ? value.effect : <></>
      }
    });
  },
  getBG: function(default_name) {
    const date = new Date();
    let name = default_name;
    this.getDatasComemorativas().forEach((value) => {
      if(date.getTime() >= value.initDate && date.getTime() <= value.finishDate)
      {
        name = value.name;
        return;
      }
    });
    return name;
  },
  getEffect: function() {
    const date = new Date();
    let effect = <></>;
    this.getDatasComemorativas().forEach((value) => {
      if(date.getTime() >= value.initDate && date.getTime() <= value.finishDate)
      {
        effect = value.effect;
        return;
      }
    });
    return effect;
  },
}

const useStyles = makeStyles(styles);

export default function Parallax(props) {  
  const [transform, setTransform] = useState("translate3d(0,0px,0)");

  const [showEffectComemorativo, setShowEffectComemorativo] = useState("");
  const [BGViewState, setBGViewState] = useState(getBGPath("default"));
  useEffect(() => {
    let bgName = datasComemorativas.getBG(props.name);
    let image = <></>;
    if(isMobile)
    {
      image = getBGPath(bgName, true);
      setBGViewState(image);
    }
    else
    {
      image = getBGPath(bgName);
      setBGViewState(image);
    }
    setShowEffectComemorativo(datasComemorativas.getEffect());
  }, [props]);
  
  const {
    filter,
    className,
    children,
    style,
    small,
    responsive,
    blocked,
  } = props;

  useEffect(() => {
    if (window.innerWidth >= 768) {
      window.addEventListener("scroll", resetTransform);
    }
    return function cleanup() {
      if (window.innerWidth >= 768) {
        window.removeEventListener("scroll", resetTransform);
      }
    };
  });

  const resetTransform = () => {
    var windowScrollTop = window.pageYOffset / 3;
    setTransform("translate3d(0," + windowScrollTop + "px,0)");
  };
  const classes = useStyles();
  const parallaxClasses = classNames({
    [classes.parallax]: true,
    [classes.filter]: filter,
    [classes.small]: small,
    [classes.parallaxResponsive]: responsive,
    [className]: className !== undefined,
  });
  
  if(blocked)
  {
    return (
      <div
        className={parallaxClasses}
        style={{
          height: "300px"
        }}
      >
        <div
          style={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Image src={BGViewState} layout="fill" alt="BB Host, datacenter de qualidade!" priority="false" objectFit="cover" quality={100} objectPosition="right" />
        </div>
        {children}
      </div>
    );
  }

  return (
    <>
    {showEffectComemorativo}
    <div
      className={parallaxClasses}
      style={{
        ...style,
        transform: transform
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
        }}
      >
        <Image src={BGViewState} layout="fill" alt="BB Host, datacenter de qualidade!" priority="false" objectFit="cover" quality={100} objectPosition="right" />
      </div>
      
      {children}
    </div>
    </>
  );
}

Parallax.propTypes = {
  className: PropTypes.string,
  filter: PropTypes.bool,
  children: PropTypes.node,
  style: PropTypes.string,
  name: PropTypes.string,
  small: PropTypes.bool,
  // this will add a min-height of 660px on small screens
  responsive: PropTypes.bool,
};
